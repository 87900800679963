// ------------------------------------------------------------ FONT IMPORT

// @import url('https://fonts.googleapis.com/css2?family=Geologica:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');

// ------------------------------------------------------------ ELEMENT DEFAULTS

// HEADINGS

h1 {
  font-size: 3.25rem; // 52px
  font-weight: 500;
}

h2 {
  font-size: 1.5625rem; // 25px
  font-weight: 500;
  // margin: 40px 0 10px 0;
}

h3 {
  font-size: 1.125rem; // 18px
  font-weight: 400;
}

// BODY

p {
  font-size: 0.875rem; // 14px
}

// OTHER

input, textarea, select, select:focus {
  font-size: 1rem !important; // 16px
}

label {
  font-size: 0.875rem; // 14px
  font-family: "Geologica";
  font-weight: 400 !important;
}

a {
  color: black;
  text-decoration: none;
}

.link {
  color: #1B74F3 !important;
  text-decoration: underline;
  cursor: pointer;
}

footer {
  .link {
    color: #1B74F3 !important;
    text-decoration: none;
    cursor: pointer;
  }
}

.Alert {
  p, a {
    font-size: 0.8125rem !important; // 13px
    font-family: "Geologica";
    font-weight: 600;
    line-height: 164%;
  }
}

// ------------------------------------------------------------ BUTTONS

.Button_primary {
  button {
    font-size: 1.125rem; // 18px
    font-family: "Geologica";
    font-weight: 500;

    @media screen and (max-width: 600px) {
      font-size: 1rem; // 16px
    }
  }
}

.Button_secondary {
  button {
    font-size: 1rem; // 16px
    font-family: "Geologica";
    font-weight: 500;
  }
}

// ------------------------------------------------------------ TYPOGRAPHY HELPERS

// WEIGHTS
.light {
  font-family: "Geologica" !important;
  font-weight: 300 !important;
}
.regular {
  font-family: "Geologica" !important;
  font-weight: 400 !important;
}
.italic {
  font-style: italic;
}
.medium {
  font-family: "Geologica" !important;
  font-weight: 500 !important;
}
.semibold {
  font-family: "Geologica" !important;
  font-weight: 600 !important;
}
.bold {
  font-family: "Geologica" !important;
  font-weight: 500 !important;

  * {
    font-family: "Geologica" !important;
    font-weight: 500 !important;
  }
}
.extrabold {
  font-family: "Geologica" !important;
  font-weight: 800 !important;
}
.bold-italic {
  font-family: "Geologica" !important;
  font-weight: 500 !important;
  font-style: italic !important;
}

// ALIGNMENT
.align_left {
  text-align: left;
}
.align_center {
  text-align: center;
}
.align_right {
  text-align: right;
}

// CURSOR
.pointer {
  cursor: pointer;
}

// STRIKETHROUGH
.strikethrough {
  text-decoration: line-through;
  color: #C72025; // variable colours.alert
  * {
    color: #C72025; // variable colours.alert
  }
}

// CODE BLOCKS
code {
  color: #CD375C;
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 1.4;
  word-wrap: break-word;

  &.block {
    display: block;
    margin: 10px 0;
  }
}