// ------------------------------------------------------------ DEFAULT VARIABLES

$duration: 0.3s;
$easing: cubic-bezier(0.65, 0, 0.35, 1);

// ------------------------------------------------------------ ELEMENT TRANSITION CLASSES

.elementTransition {
  transition:
    opacity $duration $easing,
    transform $duration $easing;
  
  &.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-30px);
  }
}

@keyframes pop {
  from {
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

// ------------------------------------------------------------ PAGE TRANSITION CLASSES

.pageTransition {
  transition:
    opacity $duration $easing,
    transform $duration $easing;
  
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

// ------------------------------------------------------------ MODAL TRANSITION
$modal-translate-distance: 20px;
.modal-enter {
  opacity: 0;

  .Modal {
    opacity: 0;
    transform: translateY($modal-translate-distance);
  }
}
.modal-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease;

  .Modal {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s 0.1s ease, transform 0.3s 0.1s ease;
  }
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease;
}