.inputColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 16px;

  &.inputColumns_desktopOnly {
    @media screen and (max-width: 600px) {
      display: block;
    }

    @media screen and (min-width: 601px) {
      display: block;
    }
  }

  .InputField, .SelectInput {
    margin-top: 0;
    margin-bottom: 25px;
  }
}

.button {
  cursor: pointer;
  transition: transform 0.2s ease; // ${transitions.default}
  user-select: none;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.975);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      transform: scale(1);
    }
    
    &:active {
      transform: scale(1);
    }
  }
}

.button_translate {
  cursor: pointer;
  transition: transform 0.3s ease;
  user-select: none;

  &:hover {
    transform: translateX(8px);
  }

  &:active {
    transform: translateX(0);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      transform: translateX(0);
    }
    
    &:active {
      transform: translateX(0);
    }
  }
}