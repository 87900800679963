// ------------------------------------------------------------ STYLE MODULE IMPORTS

@import './typography';
@import './transitions';
@import './helpers';
@import './carousel';

// ------------------------------------------------------------ GENERAL STYLES

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main.main {
  position: relative;
  margin: 0;
  padding: 0 25px 40px 25px;
  // height: 100%;
  flex-grow: 1;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}

// ------------------------------------------------------------ HIDE RECAPTCHA BADGE

.grecaptcha-badge {
  visibility: hidden;
}